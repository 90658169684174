import Layout from '../layouts/layout';
import * as styles from './doula.module.css';
import spacer from '../images/spacer-1.svg';
import ReactModal from 'react-modal';
import { useState } from 'react';

//ReactModal.setAppElement('#___gatsby');

const SanacionPage = () => {
  // const [isOpen, setIsOpen] = useState(false);

  return (
    <Layout title="Doula">
      <section className={styles.info}>
        <h1>Doula</h1>
        <p>
          Estoy dispuesta a acompañarte en la luz y la oscuridad, durante todo
          el proceso e independiente del tipo de acontecimiento, estaré ahí para
          sostenerte y apoyarte, porque el camino es uno solo, el de la maestría
          que llega con la maternidad/paternidad, a la cual tú y yo nos abrimos
          sin condición alguna, poniendo todo de nosotros, pero con la
          conciencia de que no somos los dueños de la vida.
        </p>
      </section>
      <section className={styles.products}>
        <div className={styles.spacer}>
          <img src={spacer} alt="" />
          <img src={spacer} alt="" />
          <img src={spacer} alt="" />
        </div>
        <h1>Servicios</h1>
        <div className={styles.productGrid}>
          <div className={styles.product}>
            <img src={spacer} />
            <h2>Doula de duelo perinatal</h2>
            <p>
              Mi función como doula de duelo perinatal es alumbrar el camino de
              la vida con posibilidades que no habías pensado, para transitar
              este periodo de transformación en calma, contemplándolo como un
              paso de la vida.
            </p>
            <button>Ver más</button>
          </div>
          <div className={styles.product}>
            <img src={spacer} />
            <h2>Doula de gestación, parto y posparto</h2>
            <p>
              Maternidad Conciente: Porque el amor no conoce fronteras. <br />
              <br />
              Mi misión será acompañarte a resolver esas preguntas que nos
              surgen en la gestación y en el posparto, tanto personales como
              fisiológicas. Recordándote a diario la sabiduría de tu cuerpo y
              acompañándote a reconocer la fortaleza de tu alma en esta etapa
              fisiológica de transformación. Para tomar un adecuado lugar en la
              nueva familia que nace con el amor que solo tú conoces.
              <br />
              <br /> Te invito a descubrir el mundo de la maternidad consiente,
              basado en nuestra propia sanación para expandirla con amor a
              nuestros hijos.
            </p>

            <button>Ver más</button>
          </div>
        </div>
      </section>
      {/* <ReactModal
        isOpen={isOpen}
        closeTimeoutMS={300}
        overlayClassName={styles.modalOverlay}
        className={styles.modalContent}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Doula de duelo perinatal"
      >
        <h2>Doula de duelo perinatal</h2>
        <p>
          Mi función como doula de duelo perinatal es alumbrar el camino de la
          vida con posibilidades que no habías pensado, para transitar este
          periodo de transformación en calma, contemplándolo como un paso de la
          vida.
        </p>
        <div>
          <div>
            <h2>Te puedo acompañar</h2>
            <ul>
              <li>Sesiones individuales</li>
              <li>Paquete de 3 sesiones individuales.</li>
              <li>Paquete de 4 sesiones individuales.</li>
              <li>Acompañamiento en el trabajo de parto.</li>
              <li>Acompañamiento de lactancia Matena en duelo.</li>
              <li>Cierre de dieta del nacimiento de tu bebe.</li>
            </ul>
          </div>
          <div></div>
        </div>
        <button onClick={() => setIsOpen(false)}>Cerrar</button>
      </ReactModal> */}
    </Layout>
  );
};

export default SanacionPage;
